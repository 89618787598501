import React, { useState } from 'react';
import styles from './css/AccordionList.module.css';

interface CarrierItem {
    id: string;
    title: string;
    subtitle: string;
    content?: string;
}

interface CarrierListProps {
    items: CarrierItem[];
}

const CarrierList: React.FC<CarrierListProps> = ({ items }) => {
    const [openItem, setOpenItem] = useState<string | null>(null);

    const toggleItem = (id: string) => {
        setOpenItem(openItem === id ? null : id);
    };

    return (
        <div className={styles.accordionContainer}>
            {items.map(item => (
                <div key={item.id} className={styles.accordionItem}>
                    <div
                        className={styles.accordionHeader}
                        onClick={() => toggleItem(item.id)}
                    >
                        <div className={styles.headerContent}>
                            <h3>{item.title}</h3>
                            <p>{item.subtitle}</p>
                        </div>
                        <span className={styles.toggleIcon}>
                            {openItem === item.id ? '×' : '+'}
                        </span>
                    </div>

                    {openItem === item.id && item.content && (
                        <div className={styles.accordionContent}>
                            <p>{item.content}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default CarrierList;