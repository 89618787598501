import React, { useEffect, useState } from 'react';
import TwoColumnLayout from '../components/TwoColumnLayout';
import AmplioContent from '../components/contents/AmplioContent';
import CohorsContent from '../components/contents/CohorsContent';
import CohorsContentExpanded from '../components/contents/CohorsContentExpanded';
import AmplioContentExpanded from '../components/contents/AmplioContentExpanded';
import MobileLayout from '../components/contents/MobileLayout';
import useIsMobile from '../utils/useIsMobile';

const MainLanding = () => {
    const isMobile = useIsMobile();
    const [userName, setUserName] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const rootElement = document.getElementById('root');

        if (!rootElement) return;

        // Initial values
        setUserName(rootElement.getAttribute('data-user-name') || '');
        setIsLoggedIn(rootElement.getAttribute('data-is-logged-in') === 'true');

        // Create observer for attribute changes
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes') {
                    const target = mutation.target as HTMLElement;
                    setUserName(target.getAttribute('data-user-name') || '');
                    setIsLoggedIn(target.getAttribute('data-is-logged-in') === 'true');
                }
            });
        });

        // Start observing
        observer.observe(rootElement, {
            attributes: true,
            attributeFilter: ['data-user-name', 'data-is-logged-in'] // Only watch these attributes
        });

        // Cleanup
        return () => observer.disconnect();
    }, []);

    const renderLoginText = () => {
        if (isLoggedIn && userName) {
            return <p onClick={() => navigateTo('logout')}>MY AMPLIO [ {userName} ] - LOGOUT</p>;
        }
        return <p onClick={() => navigateTo('login')}>LOGIN / REGISTER</p>;
    };

    const navigateTo = (path: string) => {
        // Ellenőrizzük, hogy a path perjellel kezdődik-e
        const normalizedPath = path.startsWith('/') ? path : `/${path}`;

        // Az aktuális domain gyökeréhez adjuk hozzá az új útvonalat
        const baseUrl = window.location.origin;
        const newUrl = `${baseUrl}${normalizedPath}`;

        // Átirányítás az új URL-re
        window.location.href = newUrl;
    };

    if (isMobile) {
        return <MobileLayout />;
    }

    return (
        <TwoColumnLayout
            leftContent={<AmplioContent />}
            rightContent={<CohorsContent />}
            leftContentExpanded={<AmplioContentExpanded loginComponent={renderLoginText()} navigateTo={navigateTo} />}
            rightContentExpanded={<CohorsContentExpanded loginComponent={renderLoginText()} navigateTo={navigateTo} />}
        />
    );
};

export default MainLanding;
