import React, { useState } from 'react';
import styles from './css/AccordionList.module.css';

interface AccordionItemContent {
    projectName: string;
    amount: string;
    support: string;
    description: string;
}

interface AccordionItem {
    id: string;
    title: string;
    date: string;
    content?: AccordionItemContent;
}

interface AccordionListProps {
    items: AccordionItem[];
}

const AccordionList: React.FC<AccordionListProps> = ({ items }) => {
    const [openItem, setOpenItem] = useState<string | null>(null);

    const toggleItem = (id: string) => {
        setOpenItem(openItem === id ? null : id);
    };

    return (
        <div className={styles.accordionContainer}>
            {items.map(item => (
                <div key={item.id} className={styles.accordionItem}>
                    <div
                        className={styles.accordionHeader}
                        onClick={() => toggleItem(item.id)}
                    >
                        <div className={styles.headerContent}>
                            <h3>{item.title}</h3>
                            <p>{item.date}</p>
                        </div>
                        <span className={styles.toggleIcon}>
                            {openItem === item.id ? '×' : '+'}
                        </span>
                    </div>

                    {openItem === item.id && item.content && (
                        <div className={styles.accordionContent}>
                            <h4>{item.content.projectName}</h4>
                            <p>{item.content.amount}</p>
                            <p>{item.content.support}</p>
                            <p>{item.content.description}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default AccordionList; 