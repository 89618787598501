import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
export type SelectedSide = "left" | "right";

interface TwoColumnLayoutProps {
    leftContentExpanded: ReactNode;
    rightContentExpanded: ReactNode;
    leftContent: ReactNode;
    rightContent: ReactNode;
}

interface TwoColumnContextType {
    side: SelectedSide;
    setSide: (side: SelectedSide) => void;
}

// Create context
export const TwoColumnContext = createContext<TwoColumnContextType | undefined>(undefined);

// Custom hook for using the context
export const useTwoColumn = () => {
    const context = useContext(TwoColumnContext);
    if (!context) {
        throw new Error('useTwoColumn must be used within a TwoColumnLayout');
    }
    return context;
};

const TwoColumnLayout = ({ leftContent, rightContent, leftContentExpanded, rightContentExpanded }: TwoColumnLayoutProps) => {
    const getInitialSide = (): SelectedSide => {
        const hostname = window.location.hostname.toLowerCase();
        if (hostname.includes('cohors')) {
            return 'right';
        }
        return 'left';
    };

    const [side, setSide] = useState<SelectedSide>(getInitialSide());

    useEffect(() => {
        const currentSide = getInitialSide();
        if (side !== currentSide) {
            setSide(currentSide);
        }
    }, []);

    useEffect(() => {
        if (side === 'left') {
            document.title = 'AMPLIO';
            // Amplio favicon beállítása
            setFavicon('/img/favicon_amplio.ico');
        } else {
            document.title = 'COHORS';
            // Cohors favicon beállítása
            setFavicon('/img/favicon_cohors.ico');
        }
    }, [side]);

    // Favicon beállító segédfüggvény
    const setFavicon = (faviconPath: string) => {
        const favicon = document.querySelector<HTMLLinkElement>("link[rel='icon']");
        if (favicon) {
            favicon.href = faviconPath;
        } else {
            const newFavicon = document.createElement('link');
            newFavicon.rel = 'icon';
            newFavicon.href = faviconPath;
            document.head.appendChild(newFavicon);
        }
    };

    return (
        <TwoColumnContext.Provider value={{ side, setSide }}>
            <div style={{ display: 'flex', width: '100%' }}>
                <div
                    style={{
                        width: side === "left" ? "calc(100% - 88px)" : "88px",
                        transition: "all 0.2s ease-in-out",
                    }}
                >
                    {side === "left" ? leftContentExpanded : leftContent}
                </div>
                <div
                    style={{
                        width: side === "right" ? "100%" : "88px",
                        transition: "all 0.2s ease-in-out",
                    }}
                >
                    {side === "right" ? rightContentExpanded : rightContent}
                </div>
            </div>
        </TwoColumnContext.Provider>
    );
};

export default TwoColumnLayout; 