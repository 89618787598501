import React from 'react';
import './App.css';
import MainLanding from './pages/MainLanding';

function App() {
  return (
    <MainLanding />
  );
}

export default App;
