import React from 'react';
import styles from '../contents/css/CohorsContent.module.css';
import { useTwoColumn } from '../TwoColumnLayout';


const CohorsContent = () => {
    const { setSide } = useTwoColumn();

    return (
        <div className={styles.contentMainBox}
            onClick={() => window.location.href = 'https://cohors.hu'}>
            <div className={styles.iconBox}>
                <img src="/img/cohors_figma.png" className={styles.cohorsIcon} alt="Cohors icon" />
            </div>
            <div className={styles.exploreBox}>
                <div className={styles.exploreString}>
                    EXPLORE COHORS
                </div>
            </div>
        </div>
    );
};

export default CohorsContent;