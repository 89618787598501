import React from "react";

export const LightChain = () => {
    return (
        <svg width="406" height="234" viewBox="0 0 406 234" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_259_4713)">
                <circle cx="351.854" cy="180.005" r="24" fill="white" />
            </g>
            <g filter="url(#filter1_f_259_4713)">
                <path d="M375.854 180.006C375.854 193.261 365.108 204.006 351.854 204.006C338.599 204.006 327.854 193.261 327.854 180.006C327.854 166.751 338.599 156.006 351.854 156.006C365.108 156.006 375.854 166.751 375.854 180.006Z" fill="white" />
            </g>
            <path d="M375.854 180C375.854 193.255 365.108 204 351.854 204C338.599 204 327.854 193.255 327.854 180C327.854 166.745 338.599 156 351.854 156C365.108 156 375.854 166.745 375.854 180Z" fill="white" />
            <circle cx="24" cy="196.004" r="22" stroke="white" stroke-width="4" />
            <circle cx="106" cy="196.004" r="22" stroke="white" stroke-width="4" />
            <circle cx="186" cy="196.004" r="22" stroke="white" stroke-width="4" />
            <line x1="23.5" y1="173" x2="23.5" y2="1" stroke="white" />
            <line x1="342.961" y1="158.499" x2="274.541" y2="0.692818" stroke="white" />
            <line x1="105.5" y1="173" x2="105.5" y2="1" stroke="white" />
            <line x1="185.5" y1="173" x2="185.5" y2="1" stroke="white" />
            <defs>
                <filter id="filter0_f_259_4713" x="297.854" y="126.005" width="108" height="108" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="15" result="effect1_foregroundBlur_259_4713" />
                </filter>
                <filter id="filter1_f_259_4713" x="317.854" y="146.006" width="68" height="68" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_259_4713" />
                </filter>
            </defs>
        </svg>
    );
};
