import React, { useEffect, useRef, useState } from 'react';
import styles from './css/CohorsContentExpanded.module.css';
import { Grid2 as Grid } from '@mui/material';
import { useTwoColumn } from '../TwoColumnLayout';
import ThinkInCohors from './svg/ThinkInCohors';
import SmartModule from './svg/SmartModule';
import CohorsStepper from './svg/CohorsStepper';

interface ContentExpandedProps {
    loginComponent: React.ReactNode;
    navigateTo: (path: string) => void;
}

const CohorsContentExpanded = ({ loginComponent, navigateTo }: ContentExpandedProps) => {
    const sections = useRef<HTMLElement[]>([]);
    const { side, setSide } = useTwoColumn();

    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);

    const handleNavigation = (sectionIndex: number) => {
        if (isScrolling) return;

        setIsScrolling(true);
        setCurrentSectionIndex(sectionIndex);

        sections.current[sectionIndex]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });

        setTimeout(() => {
            setIsScrolling(false);
        }, 1000);
    };

    // Sections referenciák inicializálása
    useEffect(() => {
        sections.current[0]?.scrollIntoView({
            behavior: 'auto',
            block: 'start'
        });
        sections.current = sections.current.slice(0, 4);
    }, [side]);

    return (
        <div className={styles.contentMainBox}>
            <div className={styles.mainContent}>
                <div className={styles.navBarContent}>
                    <div className={styles.navBarLinks}>
                        <p onClick={() => handleNavigation(1)}>ABOUT COHORS</p>
                    </div>
                </div>
                <section ref={el => el ? sections.current[0] = el! : null}>
                    <div className={styles.videoBox}>
                        <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            className={styles.videoPlayer}
                        >
                            <source src="/video/cohors.mp4" type="video/mp4" />
                        </video>
                    </div>
                </section>
                <section ref={el => el ? sections.current[1] = el! : null}>
                    <div className={styles.sectionContainer}>
                        <Grid container className={styles.contentPresenter} style={{ marginTop: "320px" }}>
                            {/* Középső grid fix szélességgel */}
                            <Grid size={12} className={styles.gridCenter}>
                                <ThinkInCohors />
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <section ref={el => el ? sections.current[2] = el! : null}>
                    <div className={styles.sectionContainer}>
                        <Grid container className={styles.contentPresenter} style={{ marginTop: "144px" }}>
                            {/* Bal oldali grid */}
                            <Grid size={3} className={styles.gridLeft}>
                            </Grid>

                            {/* Középső grid fix szélességgel */}
                            <Grid size={6} className={styles.gridCenter}>
                                <div style={{ width: '100%', maxWidth: '650px' }}>
                                    <p className={styles.containerTextHighLighted}><mark className={styles.blackHighlight}>COHORS – the highly flexible and scalable modular production line solution</mark></p>
                                    <p className={styles.containerText}>
                                        COHORS is Amplio's flagship product, revolutionizing manufacturing processes across industries.<br /><br />
                                        This innovative modular production line offers flexible solutions for the efficient and cost-effective production of various products, enabling manufacturing processes to quickly adapt to market demands.<br /><br />
                                        The COHORS system is composed of independently programmable units that can produce virtually any product. As your business grows, the production line can be easily expanded or reprogrammed to accommodate new products, ensuring maximum flexibility.<br /><br />
                                        With our advanced design and simulation software, you can plan and test the entire production process in advance. The program allows you to select the appropriate housings, doors, and necessary tools, then assemble your custom production line. Its intuitive interface ensures you can visualize and validate your concept without taking unnecessary risks.
                                    </p>
                                </div>
                            </Grid>
                            <Grid size={3} className={styles.gridRight}>
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <section ref={el => el ? sections.current[3] = el! : null}>
                    <div className={styles.sectionContainer}>
                        <Grid container className={styles.contentPresenter} style={{ marginTop: "80px" }}>
                            {/* Középső grid fix szélességgel */}
                            <Grid size={12} className={styles.gridCenter}>
                                <SmartModule />
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <section ref={el => el ? sections.current[4] = el! : null}>
                    <div className={styles.sectionContainer}>
                        <Grid container className={styles.contentPresenter} style={{ marginTop: "144px" }}>
                            {/* Bal oldali grid */}
                            <Grid size={3} className={styles.gridLeft}>
                            </Grid>

                            {/* Középső grid fix szélességgel */}
                            <Grid size={6} className={styles.gridCenter}>
                                <div style={{ width: '100%', maxWidth: '650px' }}>
                                    <div className={styles.advantagesList}>
                                        <p className={styles.containerTextHighLighted}><mark className={styles.blackHighlight}>advantages of COHORS system:</mark></p>
                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>flexibility and customization:</p>
                                            <p className={styles.advantageText}>The COHORS system is fully flexible and customizable, allowing for easy reconfiguration of manufacturing steps. If production demands change, the production line can be reprogrammed and adapted to manufacture new products with minimal downtime.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>fast installation:</p>
                                            <p className={styles.advantageText}>Compared to traditional production lines, the COHORS system has a significantly shorter installation time. It can be deployed at a manufacturing site in just three months, minimizing production downtime.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>extended lifespan and sustainability:</p>
                                            <p className={styles.advantageText} >The components involved in COHORS production can be recycled or reprogrammed for other processes at the end of a production cycle. While conventional production lines are often limited to a set production volume, COHORS has major maintenance benefits as effectively infinite lifespan.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>cost- and time-efficient operation:</p>
                                            <p className={styles.advantageText} >COHORS lines for contract manufacturing and prototype production, as it eliminates the need to design new production lines for product changes or prototype development. For simpler products, a single machine with interchangeable parts suffices.</p>
                                        </div>
                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>flexibility and customization:</p>
                                            <p className={styles.advantageText} >The COHORS system is fully flexible and customizable, allowing for easy reconfiguration of manufacturing steps. If production demands change, the production line can be reprogrammed and adapted to manufacture new products with minimal downtime.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>fast installation:</p>
                                            <p className={styles.advantageText} >Compared to traditional production lines, the COHORS system has a significantly shorter installation time. It can be deployed at a manufacturing site in just three months, minimizing production downtime.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>extended lifespan and sustainability:</p>
                                            <p className={styles.advantageText} >The components involved in COHORS production can be recycled or reprogrammed for other processes at the end of a production cycle. While conventional production lines are often limited to a set production volume, COHORS has major maintenance benefits as effectively infinite lifespan.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>cost- and time-efficient operation:</p>
                                            <p className={styles.advantageText} >COHORS lines for contract manufacturing and prototype production, as it eliminates the need to design new production lines for product changes or prototype development. For simpler products, a single machine with interchangeable parts suffices.</p>
                                        </div>
                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>continuous improvement:</p>
                                            <p className={styles.advantageText} >The COHORS system is constantly evolving, with ongoing enhancements to available tools and software updates. Our goal is to make the design and manufacturing process as user-friendly as possible.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>user-friendly process:</p>
                                            <p className={styles.advantageText} >COHORS machines are equipped with software that enables to advanced programming knowledge. After an initial training period, nearly anyone can operate the COHORS manufacturing process.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>safety solutions:</p>
                                            <p className={styles.advantageText} >The software guarantees full traceability of every step and setting, making errors easy to identify and correct.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>detailed operation of cohors:</p>
                                            <p className={styles.advantageText} >During operation, products are placed into the machine, where robots perform a variety of tasks such as dispensing, manipulation, plasma treatment, or screwing. Each robot is equipped with a force/torque controller, enabling individual programming and seamless integration of processes.</p>
                                        </div>
                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>integrated vision system:</p>
                                            <p className={styles.advantageText} >The production line includes an integrated vision system capable of recognizing different part variations, ensuring each step of the process is monitored and documented.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>modular design:</p>
                                            <p className={styles.advantageText} >The modular structure allows for easy replacement of components—such as main units, subunits, and various tools—further increasing system adaptability.</p>
                                        </div>
                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>customizable machine housing:</p>
                                            <p className={styles.advantageText} >The COHORS housing design minimizes the need for custom parts. Currently, a single COHORS unit is pallet-sized, but larger configurations can be produced as needed, adapting to diverse manufacturing requirements.</p>
                                        </div>

                                        <div className={styles.advantageItem}>
                                            <p className={styles.advantageTitle}>deployable tools:</p>
                                            <p className={styles.advantageText} >The system currently supports four types of tools: dispensing, manipulation, plasma treatment, and screwing. By combining these tools, COHORS can manufacture a wide range of products.</p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid size={3} className={styles.gridRight}>
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <section ref={el => el ? sections.current[5] = el! : null}>
                    <div className={styles.sectionContainer}>
                        <Grid container className={styles.contentPresenter} style={{ marginTop: "42px" }}>
                            {/* Középső grid fix szélességgel */}
                            <Grid size={12} className={styles.gridCenter}>
                                <CohorsStepper />
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <section ref={el => el ? sections.current[6] = el! : null}>
                    <div className={styles.sectionContainer}>
                        <Grid container className={styles.contentPresenter} style={{ marginTop: "42px" }}>
                            {/* Bal oldali grid */}
                            <Grid size={3} className={styles.gridLeft}>
                            </Grid>

                            {/* Középső grid fix szélességgel */}
                            <Grid size={6} className={styles.gridCenter}>
                                <div style={{ width: '100%', maxWidth: '650px' }}>
                                    <p className={styles.containerText}>
                                        COHORS' reusability reduces costs and enhances sustainability, as it can be reconfigured for new products even after initial production ends. With an installation time of just three months, COHORS drastically shortens setup times compared to traditional production lines, which often require 1–1.5 years for design and deployment.
                                        <br /><br />
                                        The system is currently suited for manufacturing all types of electronic products, with the long-term goal of enabling the production of nearly any product.
                                    </p>
                                    <p className={styles.containerTextHighLighted}><mark className={styles.blackHighlight}>Configurator for Planning</mark></p>
                                    <p className={styles.containerText}>
                                        The COHORS configurator allows users to design their production lines in 3D on the Amplio website. This intuitive interface helps users select the right machine components and tools, enabling the comprehensive design of their production line. Additionally, the configurator provides cost estimates and ordering options, making it easy to create a tailored solution. <br /><br />
                                        Conclusion<br />
                                        COHORS is a revolutionary solution for manufacturing processes, empowering businesses to respond quickly and efficiently to market changes. Explore the possibilities COHORS offers and step into the future of manufacturing!
                                    </p>
                                </div>
                            </Grid>
                            <Grid size={3} className={styles.gridRight}>
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <section ref={el => el ? sections.current[7] = el! : null}>
                    <div className={styles.sectionContainer}>
                        <Grid container className={styles.contentPresenter} style={{ marginTop: '150px' }}>
                            <Grid size={12} className={styles.gridCenter}>
                                <div className={styles.footerWrapper}>
                                    <div className={styles.footerContainer}>
                                        <div className={styles.footerItem}>
                                            <p className={styles.footerTitle}>CONTACT</p>
                                            <div className={styles.footerClose}>+</div>
                                        </div>
                                        <div className={styles.footerItem}>
                                            <p className={styles.footerTitle}>LEGAL</p>
                                            <div className={styles.footerClose}>+</div>
                                        </div>
                                        <div className={styles.footerItem}>
                                            <p className={styles.footerTitle}>SOCIAL MEDIA</p>
                                            <div className={styles.footerClose}>+</div>
                                        </div>
                                        <div className={styles.footerItem}>
                                            <p className={styles.footerTitle}>CONTRIBUTORS</p>
                                            <div className={styles.footerClose}>+</div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </section>
            </div >
            <div className={styles.sideContent}>
                <div className={styles.iconBox}>
                    <img
                        src="/img/cohors_figma.png"
                        alt="Amplio icon"
                        className={styles.amplioIcon}
                    />
                </div>
            </div>
        </div >
    );
};

export default CohorsContentExpanded;