import React from 'react';
import styles from "./css/AmplioContent.module.css"
import { useTwoColumn } from '../TwoColumnLayout';

const AmplioContent = () => {
    const { setSide } = useTwoColumn();

    return (
        <div
            className={styles.contentMainBox}
            onClick={() => window.location.href = 'https://amplio.hu'}
        >
            <div className={styles.iconBox}>
                <img
                    src="/img/amplio_figma.png"
                    alt="Amplio icon"
                    className={styles.amplioIcon}
                />
            </div>
            <div className={styles.exploreBox}>
                <div className={styles.exploreString}>
                    EXPLORE AMPLIO
                </div>
            </div>
        </div>
    );
};

export default AmplioContent;